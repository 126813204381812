.slots {
    &-title {
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;
        color: var(--c-white);
        margin-bottom: 52px;

        @include max(tablet-portrait) {
            font-size: 22px;
            margin-bottom: 32px;
        }
    }

    &-steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 812px;
        margin: 0 auto;
        gap: 5px;

        @include max(tablet-portrait) {
            flex-direction: column;
            gap: 18px;
        }
    }

    &-step {
        display: flex;
        align-items: center;
        gap: 14px;

        @include max(tablet-portrait) {
            width: 257px;
        }

        &__icon {
            height: 56px;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;

            &::before {
                content: '';
                position: absolute;
                background-color: var(--c-yellow);
                height: 56px;
                width: 56px;
                transform: skew(-6deg);
                z-index: -1;
            }
        }

        &__icon-joystick {
            width: 28px;
            height: 21px;
        }

        &__icon-cursor {
            width: 20px;
            height: 24px;
        }

        &__icon-gift {
            width: 29px;
            height: 26px;
        }

        &__info {
            font-size: 22px;
            text-transform: uppercase;
            line-height: 1;
            color: var(--c-white);
        }

        &__arrow {
            width: 24px;
            height: 9px;

            @include max(tablet-portrait) {
                transform: rotate(90deg);
            }
        }
    }
}