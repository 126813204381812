:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --c-white: #fff;
  --c-yellow: #ffd512;
  --c-violet: #39314d;
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-default: "Akrobat", "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

@media (width >= 992px) {
  :root {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

@font-face {
  font-family: Akrobat;
  src: url("Akrobat-Black.69adb268.woff2") format("woff2"), url("Akrobat-Black.c8cf8bd4.woff") format("woff");
  font-weight: black;
  font-display: swap;
}

html, body {
  position: relative;
}

body {
  font-family: var(--font-family-default);
  width: 100vw;
  font-weight: 800;
  overflow: hidden auto;
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

.display-large {
  color: #a9a9a9;
  margin: 20px 0 0;
  font-weight: 900;
}

.page {
  background-image: url("bg-color.8eb9c6bb.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100dvh;
  padding-top: 19px;
  font-family: Akrobat;
  position: relative;
  overflow: hidden;
}

@media (width <= 991.98px) {
  .page {
    background-size: 100% 100%;
  }
}

.page:before {
  content: "";
  opacity: .3;
  background-image: url("be-chempion.9994802a.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 580px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width <= 991.98px) {
  .page:before {
    background-position: 50% 0;
    background-size: cover;
    height: 438px;
    top: 564px;
  }

  .logotype-svg {
    margin-left: 16px;
  }

  .promocode-bg img {
    max-width: 990px;
    height: 460px;
    margin: 30px auto 0;
  }
}

@media (width <= 1199.98px) {
  .promocode-title {
    margin-bottom: 300px;
    font-size: 50px;
  }
}

@media (width <= 991.98px) {
  .promocode-title {
    max-width: 320px;
    margin: 0 auto 275px;
    font-size: 42px;
    line-height: 1.24;
  }

  .promocode-word {
    margin-bottom: 10px;
    font-size: 48px;
  }

  .slots-title {
    margin-bottom: 32px;
    font-size: 22px;
  }

  .slots-steps {
    flex-direction: column;
    gap: 18px;
  }

  .slots-step {
    width: 257px;
  }

  .slots-step__arrow {
    transform: rotate(90deg);
  }
}

@media (hover: hover) {
  .bonus-btn:hover {
    transform: scale(.98);
  }
}

.logotype-svg {
  width: 164px;
  height: 54px;
  margin: 0 auto 39px;
  display: block;
}

@media (width <= 991.98px) {
  .logotype-svg {
    margin-left: 16px;
  }
}

.promocode {
  z-index: 2;
  position: relative;
}

.promocode-bg {
  z-index: -1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.promocode-bg img {
  object-fit: cover;
  max-width: 1042px;
  height: 661px;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

@media (width <= 991.98px) {
  .promocode-bg img {
    max-width: 990px;
    height: 460px;
    margin: 30px auto 0;
  }
}

.promocode-title {
  text-transform: uppercase;
  color: var(--c-white);
  text-align: center;
  margin-bottom: 280px;
  padding-top: 20px;
  font-size: 80px;
  line-height: 1;
}

@media (width <= 1199.98px) {
  .promocode-title {
    margin-bottom: 300px;
    font-size: 50px;
  }
}

@media (width <= 991.98px) {
  .promocode-title {
    max-width: 320px;
    margin: 0 auto 275px;
    font-size: 42px;
    line-height: 1.24;
  }
}

.promocode-word {
  text-align: center;
  color: var(--c-yellow);
  font-size: 120px;
}

@media (width <= 991.98px) {
  .promocode-word {
    margin-bottom: 10px;
    font-size: 48px;
  }
}

.slots-title {
  text-align: center;
  text-transform: uppercase;
  color: var(--c-white);
  margin-bottom: 52px;
  font-size: 32px;
}

@media (width <= 991.98px) {
  .slots-title {
    margin-bottom: 32px;
    font-size: 22px;
  }
}

.slots-steps {
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  max-width: 812px;
  margin: 0 auto;
  display: flex;
}

@media (width <= 991.98px) {
  .slots-steps {
    flex-direction: column;
    gap: 18px;
  }
}

.slots-step {
  align-items: center;
  gap: 14px;
  display: flex;
}

@media (width <= 991.98px) {
  .slots-step {
    width: 257px;
  }
}

.slots-step__icon {
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
  position: relative;
}

.slots-step__icon:before {
  content: "";
  background-color: var(--c-yellow);
  z-index: -1;
  width: 56px;
  height: 56px;
  position: absolute;
  transform: skew(-6deg);
}

.slots-step__icon-joystick {
  width: 28px;
  height: 21px;
}

.slots-step__icon-cursor {
  width: 20px;
  height: 24px;
}

.slots-step__icon-gift {
  width: 29px;
  height: 26px;
}

.slots-step__info {
  text-transform: uppercase;
  color: var(--c-white);
  font-size: 22px;
  line-height: 1;
}

.slots-step__arrow {
  width: 24px;
  height: 9px;
}

@media (width <= 991.98px) {
  .slots-step__arrow {
    transform: rotate(90deg);
  }
}

.bonus {
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  margin-bottom: 100px;
  padding: 85px 20px 0;
  line-height: 1;
  position: relative;
  top: -40px;
}

.bonus-lines {
  z-index: -1;
  width: 358px;
  height: 358px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bonus-info__fs {
  color: var(--c-white);
  margin-bottom: 6px;
  font-size: 22px;
}

.bonus-info__summ {
  color: var(--c-white);
  margin-bottom: 16px;
  font-family: Akrobat, sans-serif;
  font-size: 48px;
}

.bonus-info__detail {
  z-index: 2;
  width: 202px;
  height: 73px;
  color: var(--c-yellow);
  margin: 0 auto 51px;
  padding: 10px 4px;
  font-family: Akrobat, sans-serif;
  line-height: 1;
  position: relative;
}

.bonus-info__detail:before {
  content: "";
  background-color: var(--c-violet);
  z-index: -1;
  width: 202px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-6deg);
}

.bonus-info__detail--lg {
  font-size: 30px;
}

.bonus-info__detail--sm {
  font-size: 20px;
}

.bonus-btn {
  color: var(--c-violet);
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 286px;
  height: 70px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 32px;
  transition: all .3s;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .bonus-btn:hover {
    transform: scale(.98);
  }
}

.bonus-btn:before {
  content: "";
  background-color: var(--c-yellow);
  z-index: -1;
  filter: drop-shadow(0 0 30px #ffd40a80);
  width: 286px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-6deg);
}

.footer {
  z-index: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
  display: flex;
  position: relative;
}

.footer:before {
  content: "";
  opacity: .5;
  filter: blur(559px);
  z-index: -1;
  background-color: #000;
  width: 100%;
  height: 522px;
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
}

.footer-logo {
  width: 92px;
  height: 70px;
}
/*# sourceMappingURL=index.7a9ea779.css.map */
