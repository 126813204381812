.promocode {
    position: relative;
    z-index: 2;

    &-bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        img {
            max-width: 1042px;
            height: 661px;
            max-height: 100%;
            object-fit: cover;
            display: block;
            margin: 0 auto;

            @include max(tablet-portrait) {
                max-width: 990px;
                height: 460px;
                margin: 30px auto 0;
            }
        }
    }

    &-title {
        text-transform: uppercase;
        font-size: 80px;
        color: var(--c-white);
        text-align: center;
        line-height: 1;
        margin-bottom: 280px;
        padding-top: 20px;

        @include max(tablet-landscape) {
            font-size: 50px;
            margin-bottom: 300px;
        }

        @include max(tablet-portrait) {
            font-size: 42px;
            max-width: 320px;
            margin: 0 auto 275px;
            line-height: 1.24;
        }
    }

    &-word {
        font-size: 120px;
        text-align: center;
        color: var(--c-yellow);

        @include max(tablet-portrait) {
            font-size: 48px;
            margin-bottom: 10px;
        }
    }
}
