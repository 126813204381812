.logotype {
  &-svg {
    display: block;
    width: 164px;
    height: 54px;
    margin: 0 auto 39px;

    @include max(tablet-portrait) {
      margin-left: 16px;
    }
  }
}