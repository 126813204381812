.bonus {
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  padding: 85px 20px 0;
  margin-bottom: 100px;
  line-height: 1;
  position: relative;
  top: -40px;

  &-lines {
    position: absolute;
    bottom: 0;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 358px;
    height: 358px;
    z-index: -1;
  }

  &-info {
    &__fs {
      font-size: 22px;
      color: var(--c-white);
      margin-bottom: 6px;
    }

    &__summ {
      font-family: "Akrobat", sans-serif;
      font-size: 48px;
      color: var(--c-white);
      margin-bottom: 16px;
    }

    &__detail {
      font-family: "Akrobat", sans-serif;
      position: relative;
      width: 202px;
      height: 73px;
      padding: 10px 4px;
      margin: 0 auto 51px;
      z-index: 2;
      line-height: 1;
      color: var(--c-yellow);

      &::before {
        content: '';
        position: absolute;
        background-color: var(--c-violet);
        width: 202px;
        height: 73px;
        transform: skew(-6deg);
        left: 0;
        top: 0;
        z-index: -1;
      }

      &--lg {
        font-size: 30px;
      }

      &--sm {
        font-size: 20px;
      }
    }
  }

  &-btn {
    font-size: 32px;
    color: var(--c-violet);
    width: 286px;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0 20px;
    z-index: 2;
    transition: all .3s ease;

    @media (hover: hover) {
      &:hover {
        transform: scale(0.98);
      }
    }

    &::before {
      content: '';
      position: absolute;
      background-color: var(--c-yellow);
      width: 286px;
      height: 70px;
      transform: skew(-6deg);
      z-index: -1;
      left: 0;
      top: 0;
      filter: drop-shadow(0 0 30px #ffd40a80);
    }
  }
}