@mixin max($size) {
  @if $size == desktop {
    @media (width <= 1499.98px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media (width <= 1199.98px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (width <= 991.98px) {
      @content;
    }
  } @else if $size == phone-landscape {
    @media (width <= 767.98px) {
      @content;
    }
  } @else if $size == phone-portrait {
    @media (width <= 575.98px) {
      @content;
    }
  } @else if $size == touch {
    @media (width <= 1200px) and (any-pointer: coarse) {
      @content;
    }
  }
}

@mixin min($size) {
  @if $size == desktop {
    @media (width >= 1500px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media (width >= 1200px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (width >= 992px) {
      @content;
    }
  } @else if $size == phone-landscape {
    @media (width >= 768px) {
      @content;
    }
  } @else if $size == phone-portrait {
    @media (width >= 576px) {
      @content;
    }
  } @else if $size == touch {
    @media (width >= 1200px) and (any-pointer: fine) {
      @content;
    }
  }
}
