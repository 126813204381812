@font-face {
  font-family: 'Akrobat';
  src:
    url('../fonts/Akrobat-Black.woff2') format("woff2"),
    url('../fonts/Akrobat-Black.woff') format("woff");
  font-weight: black;
  font-display: swap;
}

html,
body {
  position: relative;
}

body {
  font-family: var(--font-family-default);
  font-weight: 800;
  overflow: hidden auto;
  width: 100vw;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}