:root {
  // z-orders
  --z-index-header: 1;

  // colors
  --c-white: rgb(255 255 255);
  --c-yellow: #FFD512;
  --c-violet: #39314D;

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >=992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-default: "Akrobat",
  "Inter",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}
