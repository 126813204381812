.page {
  background-image: url('../images/webp/bg-color.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 19px;
  min-height: 100dvh;
  overflow: hidden;
  position: relative;
  font-family: 'Akrobat';

  @include max(tablet-portrait) {
    background-size: 100% 100%;
  }

  &::before {
    content: '';
    background-image: url('../images/webp/be-chempion.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 580px;
    opacity: .3;

    @include max(tablet-portrait) {
      background-position: 50% 0;
      background-size: cover;
      height: 438px;
      top: 564px;
    }
  }
}