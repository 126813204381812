.footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        bottom: -200px;
        left: 50%;
        height: 522px;
        width: 100%;
        background-color: #000;
        transform: translateX(-50%);
        opacity: .5;
        filter: blur(559px);
        z-index: -1;
    }

    &-logo {
        width: 92px;
        height: 70px;
    }
}